import React, { useState, useEffect } from 'react'
// import Header from '../components/header'
// import Footer from '../components/footer'
import "../css/sharan-style.css";
import "../css/animate.min.css";
import "../css/style.css";
import PartnerUniversities from '../components/partner-universities';
import PartnerFeatures from '../components/partner-features-component';
import PartnerTestimonials from '../components/partner-testimonials';
import { Helmet } from "react-helmet";
import PopupForm from '../components/popupForm';
import Layout from '../components/layout';
import { graphql } from "gatsby";



const Partnership = (wpPageData, pageContext) => {

  const post = wpPageData.data.allWordpressPage.nodes[0];
  const uniDataAll = wpPageData.data.allWordpressWpUniversities.nodes;

  const featuredIds = post.acf.featured_universities;

  const uniData = featuredIds.map(id => 
  uniDataAll.find(uni => uni.wordpress_id === id)
  ).filter(uni => uni !== undefined);



  const metatitle = post.acf.meta_title;
  const metadescription = post.acf.meta_description;
  const focus_keyphrase = post.acf.focus_keyphrase;
  const canonical_url = `https://theworldgrad.com/partnership/`;

  const [time, setTime] = useState(9000000000000)

  useEffect(() => {
    setTime(9000000000000)
  }, [])

  const handlePopupWindow = () => {
    setTime(Math.random())
  }

  return (
    <div>
      <div className="col-12">
        <button
          className="btn fixed-enquire-button"
          onClick={handlePopupWindow}
        >
          Enquire
        </button>
      </div>
      <PopupForm time={time} />
      <Layout>
        <Helmet>
          <title>{metatitle}</title>
          <meta name="description" content={metadescription} />
          {(() => { if (post.acf.focus_keyphrase != '' && post.acf.focus_keyphrase != null) { return (<meta name="keywords" content={focus_keyphrase} />) } })()}
          <link rel="canonical" href={canonical_url} /> 
          {(() => { if (post.acf.dynamic_schema != '' && post.acf.dynamic_schema != null) { return (<script type="application/ld+json">{JSON.stringify(JSON.parse(post.acf.dynamic_schema))}</script>) } })()}
          

    
        </Helmet>
 {/*canonical_url*/}
        <div class="newStyle">
          <div className="container">
            <ol class="transparentbreadcrumb breadcrumb breadcrumb-new">
              <li class="breadcrumb-item">
                <a href="/">Home </a>
              </li>
              <li class="breadcrumb-item active"> {post.title}</li>
            </ol>
          </div>
        </div>
        <div className="container-fluid partnership-main-container" style={{ fontFamily: "'Poppins', sans-serif;" }}>
          <div className="row">
            <div className="col-12 partnership-header-image-container">
              {/* <Header /> */}
              {/*<p className="partnership-below-header-text" > Worldgrad  —  Partner with us </p>*/}
              <h1 className="partnership-header-main-text" >{post.acf.custom_h1}</h1>
              <p className="partnership-header-sub-text">{post.acf.sub_heading}</p>
            </div>
            <div className="col-12">
              <PartnerUniversities featuredUniversities={uniData} />
            </div>
            <div className="col-12 partner-features-container">
              <PartnerFeatures featuredContent={post.acf.featured_content}  programModel={post.acf.program_model} worldgradOffers={post.acf.worldgrad_offers} worldgradSupport={post.acf.worldgrad_support}/>
            </div>
            <div className="col-12 my-5">
              <PartnerTestimonials partnerTestimonials={post.acf.partner_testimonials}/>
            </div>
            <div className="col-12 p-0">
              {/* <Footer /> */}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Partnership


export const PartnershipPage = graphql`
    {
      allWordpressPage(filter: {wordpress_id: {in: 4931}}) {
        nodes {
          title
          wordpress_id
          acf {
             
            meta_title
            meta_description
            focus_keyphrase
            canonical_url
            dynamic_schema
            custom_h1       
            sub_heading
            featured_universities
            featured_partner_universities_section {
              featured_partner_universities_title
            
            }

featured_content{
            featured_content_image {
              source_url
            }
            featured_content_title

            featured_content {
              content
              read_more_required
            }
          }
          program_model{
            program_model_title
            program_model_icon_1 {
              source_url
            }
            program_model_title_1
            program_model_content_1
            program_model_icon_2 {
              source_url
            }
            program_model_title_2
            program_model_content_2
            program_model_icon_3 {
              source_url
            }
            program_model_title_3
            program_model_content_3
}
worldgrad_offers {
            worldgrad_offers_image {
              source_url
            }
            worldgrad_offers_title
            worldgrad_offers_subtitle

            worldgrad_offers_content {
              offers_title
              offers_content
            }


            worldgrad_offers_button_title
            worldgrad_offers_button_link
}
            worldgrad_support{
            worldgrad_support_image {
              source_url
            }
            worldgrad_support_title
            worldgrad_support_button
            worldgrad_support_button_link
            worldgrad_support_detail {
              worldgrad_support_content
            }
            
            }
            
            partner_testimonials {
              partner_testimonials_title
              partner_testimonials_detail {
                partner_testimonial
                person_designation
                person_name
                partner_testimonial_image {
                  source_url
                }
              }
  }
            


      }
    }
}      
     allWordpressWpUniversities {
				nodes {
				  id
				  slug
				  title
          	  wordpress_id
				  acf {
					country
             website_url
          university_detail_page
					university_logo {
          source_url
        }
				  }
				}
			  }
}
`

